@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css';

@import "../../../common-assets/css/variables";
@import "../../../common-assets/css/rootui-parts/element-popup";
@import "../../../common-assets/css/rootui-parts/plugin-fancybox";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@media screen and (max-width: 766px) {
    .rui-page .rui-page-content,
    .container-fluid,
    .container {
        padding: 0;
    }

    .rui-gmap {
        margin: 0 !important;
    }
}

// Fix container class on popup.
.rui-popup.container {
    @include make-container-max-widths();
}

.rui-gmap {
    margin: 10px;
}

.card .list-group-item {
    padding: 0;
}

.media.media-filled > .media-link {
    padding: 10px;
}

.media .media-img {
    height: 64px;
    width: 64px;
    flex: 0 0 64px;
}

.btn-group-item {
    background-color: transparent;
    border: 1px solid #e6ecf0;
    padding: 14px 16px;
    color: #6c757d;
}

// AdServer Ad Adjustments
.zid-103055 {
    width: 100%;
}
.zid-103055 > div > div {
    display: flex;
}
